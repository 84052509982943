

























































































import { Vue, Component } from 'vue-property-decorator'

@Component
export default class EquipmentDetail extends Vue {
  backToEquipments() {
    this.$router.push({ name: '设备管理' })
  }
}
